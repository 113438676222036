import { Helmet as SEOTenant } from "react-helmet";
import { useSeo } from "./useSeo";

export const Helmet = () => {
  const seo = useSeo();

  if (!seo) {
    return null;
  }

  return (
    <SEOTenant>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta property="og:title" content={seo.seo.title} />
      <meta property="og:description" content={seo.seo.metaDescription} />
      <meta property="og:image" content={seo.seo.ogImage} />
      <meta property="og:image:alt" content={seo.seo.ogImageAlt} />
      <meta property="og:url" content={seo.seo.ogurl} />
      <meta property="og:type" content={seo.seo.ogType} />
      <meta property="og:locale" content={seo.seo.ogLocale} />
      <meta property="og:site_name" content={seo.seo.ogSiteName} />
    </SEOTenant>
  );
};
