import apiRequest from "../../../../api/apiRequest";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../contexts/AuthContext";
import { CircularProgress, Box } from "@mui/material";
import { Status } from "../../../../api/Codes";
import { Flip, toast, ToastContainer } from "react-toastify";

export const EmailAuthentication = () => {
  const { setAuth, isAuthenticated } = useContext(AuthContext);
  let location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const emailToken = queryParameters.get("email_token");
  const redirectTo = queryParameters.get("redirect_to");
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      authenticateUser();
    }
  }, []);

  const authenticateUser = () => {
    apiRequest({
      method: "post",
      url: "/email-auth",
      data: {
        emailToken,
      },
    })
      .then(response => {
        setAuth(response.user, `Bearer ${response.token.value}`);
        window.location.href = redirectTo ? redirectTo : "/";
      })
      .catch(error => {
        if (error.status === Status.Unauthorized) {
          toast.error("Something went wrong!", {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Flip,
          });
          setTimeout(() => {
            history.push("/");
          }, 500);
        }
      });
  };

  if (!emailToken || isAuthenticated) {
    return <Redirect exact to={redirectTo ? redirectTo : ROUTES.HOME} />;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "2em", marginBottom: "2em" }}>
      <CircularProgress />
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Flip}
      />
    </Box>
  );
};
