import React from "react";
import Tenants from "./SEO.json";
import ConfigurationContext from "../contexts/ConfigurationContext";

export const useSeo = () => {
  const { configurations } = React.useContext(ConfigurationContext);
  const programName = configurations.program_name;

  return Tenants[programName];
};
