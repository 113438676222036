import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";

export const SSOAuthenticate = () => {
  const history = useHistory();
  const { setAuth } = useContext(AuthContext);

  useEffect(() => {
    // Parse the token from the query string
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const user = urlParams.get("user");

    if (token) {
      const tokenData = JSON.parse(token);
      const userData = JSON.parse(user);

      setAuth(userData, `Bearer ${tokenData?.value}`);
      window.parent.location.href = "/";
    } else {
      console.error("Token not found in URL");
    }
  }, [history]);

  return <div>Authenticating...</div>;
};
